import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { Button, message, notification } from 'antd'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import ChatHeader from './ChatHeader'
import ChatComponent, { ConversationMessageItem } from '../../components/Chat/Chat'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import Icon from '../../components/UI/Icons/Icons'
import { GetChatRequest } from '../../types/public.type'
import {
  useCreateConversationMutation,
  useLazyGetChatConfigQuery,
  useLazyGetConversationQuery,
  useSendChatMessageMutation,
} from '../../store/api/pubic.api'
import { Conversation, ConversationImageUrl, ConversationMessageRequestBody } from '../../types/conversation.type'
import { useUploadFileMutation } from '../../store/api/uploads.api'
import { Message } from '../../types/storage.type'
import './publicChat.less'

const copyUrlToClipboard = () => {
  const url = window.location.href
  navigator.clipboard
    .writeText(url)
    .then(() => {
      message.success('Page link has been copied to the clipboard!')
    })
    .catch((error) => {
      console.error('Failed to copy URL to clipboard:', error)
    })
}

const Chat = () => {
  const location = useLocation()
  const { accountId } = useParams()

  const listRef = useRef<HTMLDivElement>(null)

  /** Storage */
  // const sett = useAppSelector((state) => state.chat)

  /** URL params */
  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search)
    const storageId = queryParams.get('storageId') || ''
    const agentId = queryParams.get('agentId') || ''
    const conversationId = queryParams.get('conversationId') || ''
    const name = queryParams.get('name') || ''
    const email = queryParams.get('email') || ''
    return {
      storageId,
      agentId,
      conversationId,
      customerData:
        name && email
          ? {
              name,
              email,
            }
          : null,
    }
  }

  const removeQueryParams = () => {
    const queryParams = new URLSearchParams(location.search)

    const conversationId = queryParams.get('conversationId')

    const newQueryParams = new URLSearchParams()
    if (conversationId) {
      newQueryParams.set('conversationId', conversationId)
    }

    const newUrl = `${location.pathname}?${newQueryParams.toString()}`
    window.history.pushState({}, '', newUrl)
  }

  /** STATE */
  const [conversation, setConversation] = useState<Conversation>({
    id: getQueryParams().conversationId,
    agentId: '',
    accountId: '',
    analytics: undefined,
    createdAt: '',
    customerEmail: '',
    customerName: '',
    hasStorage: false,
    messages: [],
    storageId: '',
    unread: false,
    updatedAt: '',
    userName: '',
    userId: '',
  })
  const [isConversationHasStorage, setIsConversationHasStorage] = useState<boolean>(true)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

  /** API */
  const [getConversationById, { isFetching: isMessagesDataFetching }] = useLazyGetConversationQuery()

  const [sendChatMessage, { data, error, isLoading: isMessageSending, isSuccess }] = useSendChatMessageMutation()
  const [uploadFile, uploadFileMutationResult] = useUploadFileMutation()
  const [getChatConfig, { data: chatSettings }] = useLazyGetChatConfigQuery()
  const [createConversation, { isLoading: isCreatingConversationLoading }] = useCreateConversationMutation()

  /** Response message */
  useErrorMessage('Something went wrong', error)
  useErrorMessage('Something went wrong', uploadFileMutationResult.error)

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight
    }
  }

  /**  handlers */

  const createNewConversation = async () => {
    let newConversation: Conversation | undefined = undefined
    const { agentId, storageId, customerData } = getQueryParams()
    const result = await createConversation({ accountId, agentId, storageId, customerData })
    if ('data' in result && result.data.id) {
      removeQueryParams()
      setConversation(result.data)
      newConversation = result.data
      setConversationIdParam(result.data.id)
      if (accountId) {
        await getChatConfig({ id: accountId, agentId: result.data.agentId })
      }
    } else if ('error' in result) {
      const { error } = result as unknown as { error: { data: { message: string } } }
      notification.error({ message: error?.data?.message || 'Something went wrong' })
    }
    return newConversation
  }

  const fetchConversation = () => {
    if (conversation?.id) {
      getConversationById({ conversationId: conversation.id }).then(({ data }) => {
        if (data?.id) {
          if (accountId && isFirstLoad) {
            getChatConfig({ id: accountId, agentId: data.agentId })
            setIsFirstLoad(false)
          }
          setConversation(data)
        }
      })
    }
  }

  const setConversationIdParam = (conversationId: string) => {
    const currentUrl = window.location.href

    const url = new URL(currentUrl)
    url.searchParams.set('conversationId', conversationId)
    window.history.pushState({}, '', url)
  }

  const uploadImage = async (image: File, chatRequestProps: GetChatRequest) => {
    const formData = new FormData()
    formData.append('user', JSON.stringify(chatRequestProps))
    formData.append('file', image)

    const result: { data: ConversationImageUrl } | { error: FetchBaseQueryError | SerializedError } = await uploadFile({
      body: formData,
    })

    if ('data' in result) {
      return result
    }
    return null
  }

  const send = async (values: ConversationMessageItem) => {
    let newConversationId = ''
    if (!conversation.id) {
      const newConversation = await createNewConversation()
      newConversationId = newConversation?.id || ''
    }

    const chatRequestProps = {} as GetChatRequest

    const message: ConversationMessageRequestBody = {
      role: 'user',
      content: [],
    }

    if (values.message) {
      message.content.push({
        type: 'text',
        text: values.message,
      })
    }

    if (values.linksImages?.length) {
      values.linksImages.forEach((url) => {
        message.content.push({
          type: 'image_url',
          image_url: {
            url,
            s3Uri: '',
          },
        })
      })
    }

    let uploadsImageResult: Array<{ data: ConversationImageUrl }> = []

    if (values.fileImages?.length) {
      try {
        const uploadPromises = values.fileImages.map((image: File) => uploadImage(image, chatRequestProps))

        const results = await Promise.all(uploadPromises)

        uploadsImageResult = results.filter(Boolean) as Array<{ data: ConversationImageUrl }>
      } catch (err) {
        console.log('Uploads images error', err)
      }
    }

    if (uploadsImageResult?.length) {
      uploadsImageResult.forEach(({ data }) => {
        message.content.push({
          type: 'image_url',
          image_url: data,
        })
      })
    }

    sendChatMessage({ conversationId: newConversationId || conversation.id, message }).then((data) => {
      if ('data' in data && data.data.id) {
        setConversation(data.data)
      }
    })
  }

  /**  useEffect */
  useEffect(() => {
    getChatConfig({ id: accountId || '' })
    fetchConversation()
  }, [])

  useEffect(() => {
    if (!conversation?.id) {
      return
    }
    setIsConversationHasStorage(!!conversation?.storageId || false)
  }, [conversation])

  useEffect(() => {
    scrollToBottom()
  }, [conversation])

  const initialData = useMemo(() => {
    let result = {
      id: '',
      messages: [] as Message[],
    }

    if (data) {
      result = cloneDeep(data)
    }

    if (chatSettings?.chatSettings?.welcomeMessage) {
      result.messages.unshift({
        role: 'assistant',
        content: chatSettings?.chatSettings?.welcomeMessage,
      })
    }

    return result
  }, [data, chatSettings])

  return (
    <div className="public-chat-page">
      <ChatHeader />

      <div className="public-chat-container">
        <ChatComponent
          ref={listRef}
          isEditable
          isPublic
          onSend={send}
          isFetching={isMessageSending || isMessagesDataFetching || isCreatingConversationLoading}
          placeholder="Ask a question"
          successMessageSend={isSuccess}
          messages={conversation?.messages?.length ? conversation.messages : initialData?.messages}
          refetchMessages={fetchConversation}
          conversationId={conversation.id}
          isVoiceModeAllowed={isConversationHasStorage && chatSettings?.realtimeSettings?.enabled}
          createConversation={createNewConversation}
        />

        <Button className="copy-url-button" icon={<Icon name="copyUrl" />} type="link" onClick={copyUrlToClipboard}>
          <span className="button-text">copy page link</span>
        </Button>
      </div>
    </div>
  )
}

export default Chat
