import React, { useEffect, useState } from 'react'
import { Tabs, TabsProps } from 'antd'
import TestChatButton from '../../TestChatButton/TestChatButton'
import AddHtmlOrTextTab from './components/tabs/AddHtmlOrTextTab/AddHtmlOrTextTab'
import AddByUrlTab from './components/tabs/AddByUrlTab/AddByUrlTab'
import AddFileTab from './components/tabs/AddFileTab/AddFileTab'
import AddFileByUrlTab from './components/tabs/AddFileByUrlTab/AddFileByUrlTab'



type AddNewDataTabProps = {
  setKnowledgeBasePage: (key: string) => void
  addNewDataActivePage: string
}

const AddNewDataTab = ({ setKnowledgeBasePage, addNewDataActivePage }: AddNewDataTabProps) => {

  const [activeKey, setActiveKey] = useState<string>('1')

  useEffect(() => {
    setActiveKey(addNewDataActivePage)
  }, [addNewDataActivePage])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Add data in HTML or Text',
      children: <AddHtmlOrTextTab />,
    },
    {
      key: '2',
      label: 'Add document',
      children: <AddFileTab setKnowledgeBasePage={setKnowledgeBasePage}  />,
    },
    {
      key: '3',
      label: 'Add data by individual URL',
      children: <AddByUrlTab />,
    },
    {
      key: '4',
      label: 'Add document by individual URL',
      children: <AddFileByUrlTab setKnowledgeBasePage={setKnowledgeBasePage}  />,
    },
  ]
  return (
    <div className="add-new-data-tab">
      <div className="tab-header">
        <div className="header-description">
          <h2>Add New Data</h2>
          <p>
            Here you may add HTML, documents and text pages to your Knowledge Base. You may also index an individual URL from
            your website or landing page.
          </p>
        </div>
        <TestChatButton />
      </div>
      <Tabs  activeKey={activeKey} items={items} onChange={setActiveKey} destroyInactiveTabPane />
    </div>
  )
}

export default AddNewDataTab
