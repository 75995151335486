import React from 'react'
import Logo from '../../Logos/Logo'
import { Button } from 'antd'
import './googleButton.less'

interface IProps {
  handleGoogleOAuth: () => void
}

const GoogleButton = ({ handleGoogleOAuth }: IProps) => {
  return (
    <Button
      type="link"
      icon={<Logo name='Google' width='100%' height='100%' className='integration-icon' />}
      className={'integration-btn'}
      size='large'
      onClick={handleGoogleOAuth}
    >
      Connect Google Account
    </Button>
  )
}

export default GoogleButton
