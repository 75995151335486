import { IKnowledgeBaseDocument } from '../../../../types/knowladgeBaseDocument.type'
import { Button, Popconfirm, Table, Tooltip, Typography } from 'antd'
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IPaginationParams } from '../../../../types/pagination-params.type'
import { TablePaginationConfig } from 'antd/es/table'

type Props = {
  documents: IKnowledgeBaseDocument[]
  onDelete: (documentId: string) => void
  isLoading: boolean
  setKnowledgeBasePage: (key: string) => void
  pagination: IPaginationParams
  count: number
  setPagination: (params: IPaginationParams) => void
}

const KnowledgeBaseDocumentsList = ({
  documents,
  count,
  onDelete,
  isLoading,
  setKnowledgeBasePage,
  pagination,
  setPagination,
}: Props) => {
  const navigate = useNavigate()

  /** Helpers */
  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
      return 'No data'
    }

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${day}.${month}.${year} ${hours}:${minutes}`
  }

  const navigateToDocument = (documentId: string) => {
    const searchParams = new URLSearchParams(location.search)
    setKnowledgeBasePage('4')
    searchParams.set('documentId', documentId)
    navigate(`${location.pathname}?${searchParams.toString()}`)
  }

  /** Table columns */
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: IKnowledgeBaseDocument) => (
        <Typography.Link onClick={() => navigateToDocument(record.id)}>{text}</Typography.Link>
      ),
    },
    {
      title: 'Uploaded At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => formatDate(date),
    },
    {
      title: 'Modified At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date: string) => formatDate(date),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: IKnowledgeBaseDocument) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Popconfirm
            title="Are you sure you want to delete this file and all related data in knowledge base? This action is not reversible."
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
            placement={'leftTop'}
          >
            <Tooltip title="Delete file">
              <Button icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
          <Tooltip title="Open file">
            <Button icon={<LinkOutlined />} onClick={() => window.open(record.url, '_blank')} />
          </Tooltip>
        </div>
      ),
    },
  ]

  /** Handlers */
  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination({
      page: pagination.current || 1,
      pageSize: pagination.pageSize || 10,
    })
  }

  return (
    <Table
      columns={columns}
      scroll={{ y: 400 }}
      dataSource={documents}
      rowKey="id"
      className="custom-scroll"
      pagination={{
        position: ['bottomCenter'],
        total: count,
        current: pagination.page,
        pageSize: pagination.pageSize,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
      }}
      loading={isLoading}
      onChange={handleTableChange}
    />
  )
}

export default KnowledgeBaseDocumentsList
