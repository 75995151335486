import React from 'react'
import { Form, Input, notification } from 'antd'
import AppPrimaryButton from '../../../../../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useAppSelector } from '../../../../../../../../hooks/appHook'
import { requiredValidator } from '../../../../../../../../helpers/validators'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCreateDocumentMutation } from '../../../../../../../../store/api/document.api'

type AddFileByUrlTabProps = {
  setKnowledgeBasePage: (key: string) => void
}
const AddFileByUrlTab = ({ setKnowledgeBasePage }: AddFileByUrlTabProps) => {

  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm()

  /** Storage */
  const {

    currentStorageId,
  } = useAppSelector((state) => state.storage)

  /** API */
  const [createDocument, createDocumentMutation] = useCreateDocumentMutation()

  const onSubmit = async (values: { query: string }) => {
    try {


      const searchParams = new URLSearchParams(location.search)
      const createdDocument = await createDocument({
        storageId: currentStorageId, url: values.query,
      }).unwrap()
      if (createdDocument.id) {
        notification.success({ message: `Successfully uploaded.` })
        setKnowledgeBasePage('4')
        searchParams.set('documentId', createdDocument.id)
        navigate(`${location.pathname}?${searchParams.toString()}`)
      }
    } catch (e: any) {
      notification.error({ message: `An error occurred, please check your link and try again.` })
    }
  }

  return (
    <div className="add-by-url-tab">
      <h3>Paste individual URL here (PDF, Doc, Docx, MD, txt)</h3>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name="query" rules={[{ required: true, validator: requiredValidator('Link is required!') }]}>
          <Input size="large" />
        </Form.Item>
        <div className="search-buttons-container">
          <Form.Item>
            <AppPrimaryButton type="primary" htmlType="submit" className="app-primary-button-medium" size="middle"
                              loading={createDocumentMutation.isLoading}>
              Index URL
            </AppPrimaryButton>
          </Form.Item>
        </div>

      </Form>
    </div>
  )
}

export default AddFileByUrlTab
