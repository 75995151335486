import React from 'react'
import { Row } from 'antd'
import { LikeOutlined, SendOutlined, SearchOutlined } from '@ant-design/icons'
import UnansweredQuestions from './components/UnansweredQuestions/UnansweredQuestions'
import StatisticsCard from './components/StatisticsCard/StatisticsCard'
import LineChart from './components/LineChart/LineChart'
import { ConversationStats } from '../../../../types/conversation.type'

interface IProps {
  data: ConversationStats
}

const Statistics = ({ data }: IProps) => {
  return (
    <div className="container" style={{ maxWidth: 900 }}>
      <Row gutter={16} style={{ marginBottom: 30 }}>
        <StatisticsCard
          precision={0}
          value={data.count}
          suffix='/ week'
          label="# of conversations"
          tooltipTitle="We analyze conversations with at least one reply, starting 6 hours after the last reply."
          prefix={<SendOutlined />}
          isStyled={false}
        />
        <StatisticsCard
          precision={1}
          value={data.averageSatisfactory}
          suffix="/ 10"
          label="Satisfactory"
          tooltipTitle="This score reflects user satisfaction after chatting with your Agent, provided by our Analytics AI."
          prefix={<LikeOutlined />}
        />
        <StatisticsCard
          precision={1}
          value={data.averageInfoFounded}
          suffix="/ 10"
          label="Info found"
          tooltipTitle="Did your agent find the requested information? If the score is low, consider updating your knowledge base. Provided by our Analytics AI Agent."
          prefix={<SearchOutlined />}
        />
      </Row>
      <Row gutter={16} style={{ marginBottom: 32, marginLeft: 4 }}>
        <LineChart chart={data.chart || []} />
      </Row>
      <UnansweredQuestions dataSource={data.missingData} />
    </div>
  )
}

export default Statistics
