import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { first } from 'lodash'
import { useGetStorageListQuery } from '../../../../store/api/storage.api'
import { setCurrentStorageId } from '../../../../store/slices/storage.slice'
import CustomSelect from '../../../../components/CustomSelect/CustomSelect'
import AiHelperHeader from '../../../../components/AIHelperHeader/aiHelperHeader'
import CreateNewStorageModal from '../forms/CreateStorageForm/components/modals/CreateNewStorageModal/CreateNewStorageModal'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useAppStateActions } from '../../../../hooks/actions'

const QUERY_STORAGE_ID_NAME = 'storageId'

const KnowledgeBasePageHeader = ({title}: {title: string}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { setIsCreateStorageModalOpen } = useAppStateActions()

  const {data: storages} = useGetStorageListQuery()

  const queryParams = new URLSearchParams(location.search);

  const firstStorageInStorages = useMemo(() => {
    return first(storages) || null
  }, [storages])

  const selectOptions = useMemo(() => {
    return storages?.map(({id, name}) => ({value: id, label: name})) || []
  }, [storages])

  const queryParamId = useMemo(() => {
    const queryId = queryParams.get(QUERY_STORAGE_ID_NAME)
    if (queryId && selectOptions.map(({value}) => value).includes(queryId)) {
      return queryId
    }
    return firstStorageInStorages?.id || ''
  }, [queryParams, firstStorageInStorages, selectOptions])

  useEffect(() => {
    dispatch(setCurrentStorageId(queryParamId))
  }, [queryParamId])

  useEffect(() => {
    /** If there is a first element in the list of storages (firstStorageInStorages)
     and the URL does not have a query parameter named QUERY_STORAGE_ID_NAME */
    if (firstStorageInStorages && !queryParams.has(QUERY_STORAGE_ID_NAME)) {
      /** Set the QUERY_STORAGE_ID_NAME parameter with the ID of the first element in the list of storages */
      queryParams.set(QUERY_STORAGE_ID_NAME, firstStorageInStorages.id);

      /** Update the URL with the new query parameter, replacing the current entry without adding a new one to the history */
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, [queryParams, firstStorageInStorages]);

  const onChangeSelect = useCallback((value: any) => {
    queryParams.set('storageId', value);
    navigate({ search: queryParams.toString() }, { replace: true });
    dispatch(setCurrentStorageId(value))
  }, [])

  const onPressCreteButton = useCallback(() => {
    setIsCreateStorageModalOpen(true)
  }, [])

  return (
    <div className="page-header">
      <CreateNewStorageModal />
      <AiHelperHeader title={title} paragraph="" />
      <div className="header-right-content">
        {storages?.length
          ? <CustomSelect
            createButtonTitle="Add storage"
            onChange={onChangeSelect}
            options={selectOptions}
            initialStatus={queryParamId}
            key={queryParamId}
            onPressCreteButton={onPressCreteButton}
          />
          : <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={onPressCreteButton}
            className="create-storage-button"
          >
            Create new storage
          </Button>}
      </div>
    </div>
  )
}

export default memo(KnowledgeBasePageHeader)
