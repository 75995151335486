import React from 'react'
import { Button, Popconfirm } from 'antd'

interface IProps {
  title: string
  description: string
  confirmHandler: (e?: (React.MouseEvent<HTMLElement, MouseEvent> | undefined), ...args: any) => void
  isLoading: boolean
}

const ConfirmedDeleteButton = ({ title, description, confirmHandler, isLoading }: IProps) => {
  return (
    <Popconfirm
      title={title}
      description={description}
      onConfirm={confirmHandler}
      okButtonProps={{ loading: isLoading }}
      okText="Yes"
      cancelText="No"
    >
      <Button type="primary" danger>DELETE</Button>
    </Popconfirm>
  )
}

export default ConfirmedDeleteButton
