import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../../hooks/appHook'
import KnowledgeBaseDocumentsList from '../../KnowledgeBaseDocumentsList/KnowledgeBaseDocumentsList'
import { useDeleteDocumentMutation, useGetDocumentsQuery } from '../../../../../store/api/document.api'
import { notification } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import AddDocumentButton from '../../../../../components/AddDocumentButton/AddDocumentButton'
import { IPaginationParams } from '../../../../../types/pagination-params.type'

type IProps = {
  setKnowledgeBasePage: (key: string) => void
  setAddNewDataActivePage: (key: string) => void
}

const DocumentsTab = ({ setKnowledgeBasePage, setAddNewDataActivePage }: IProps) => {
  const [pagination, setPagination] = useState<IPaginationParams>({
    page: 1,
    pageSize: 10,
  })

  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  /** Storage */
  const { currentStorageId } = useAppSelector((state) => state.storage)

  /** Api */
  const { data, isLoading, refetch } = useGetDocumentsQuery({ storageId: currentStorageId, pagination })
  const [deleteDocument, deleteDocumentMutation] = useDeleteDocumentMutation()

  /** Handlers */

  const onDeleteDocument = async (documentId: string) => {
    try {
      deleteDocument({ documentId })
      queryParams.delete('documentId')
      navigate({
        pathname: location.pathname,
        search: queryParams.toString(),
      })
    } catch (e: any) {
      notification.error({ message: 'Something went wrong, try again please.' })
    }
  }

  useEffect(() => {
    refetch()
  }, [pagination])
  return (
    <div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h2>Documents</h2>
          <AddDocumentButton
            setKnowledgeBasePage={setKnowledgeBasePage}
            setAddNewDataActivePage={setAddNewDataActivePage}
          />
        </div>
      </div>
      <KnowledgeBaseDocumentsList
        documents={data?.documents || []}
        onDelete={onDeleteDocument}
        isLoading={isLoading || deleteDocumentMutation.isLoading}
        setKnowledgeBasePage={setKnowledgeBasePage}
        pagination={pagination}
        setPagination={(p: IPaginationParams) => setPagination(p)}
        count={data?.count || 0}
      />
    </div>
  )
}

export default DocumentsTab
