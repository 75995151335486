import React, { useState } from 'react'
import { Tabs, TabsProps } from 'antd'
import ReviewAllDataTab from './components/tabs/ReviewAllDataTab/ReviewAllDataTab'
import AddNewDataTab from './components/tabs/AddNewDataTab/AddNewDataTab'
import SearchAndEditTab from './components/tabs/SearchAndEditTab/SearchAndEditTab'
import SettingsTab from './components/tabs/SettingsTab/SettingsTab'
import { useAppSelector } from '../../hooks/appHook'
import KnowledgeBasePageHeader from './components/KnowledgeBasePageHeader/KnowledgeBasePageHeader'
import './knowledgeBase.less'
import DocumentsTab from './components/tabs/DocumentsTab/DocumentsTab'
import { useLocation } from 'react-router-dom'
import ReviewAllDataByDocumentTab from './components/tabs/ReviewAllDataTab/ReviewAllDataByDocumentTab'

const KnowledgeBase = () => {
  /** Storage */
  const { storages } = useAppSelector((state) => state.storage)

  const [activeKey, setActiveKey] = useState<string>('1')

  const [addNewDataActivePage, setAddNewDataActivePage] = useState<string>('1')

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const documentId = queryParams.get('documentId')

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Add New Data',
      children: (
        <AddNewDataTab
          setKnowledgeBasePage={(key: string) => setActiveKey(key)}
          addNewDataActivePage={addNewDataActivePage}
        />
      ),
    },
    {
      key: '2',
      label: 'Documents',
      children: (
        <DocumentsTab
          setKnowledgeBasePage={(key: string) => setActiveKey(key)}
          setAddNewDataActivePage={setAddNewDataActivePage}
        />
      ),
    },
    {
      key: '3',
      label: 'Search & Edit Data',
      children: <SearchAndEditTab />,
    },
    {
      key: '4',
      label: 'Review Data',
      children: documentId ? (
        <ReviewAllDataByDocumentTab setKnowledgeBasePage={(key: string) => setActiveKey(key)} />
      ) : (
        <ReviewAllDataTab />
      ),
    },
    {
      key: '5',
      label: 'Settings',
      children: <SettingsTab />,
    },
  ]

  return (
    <>
      <KnowledgeBasePageHeader title="Knowledge Base" />
      <div className="container">
        {storages.length ? (
          <Tabs activeKey={activeKey} items={items} onChange={setActiveKey} destroyInactiveTabPane />
        ) : (
          <div className="empty-storages-container">
            To continue working, it is necessary to have at least one created storage. To create a storage, please click
            the “Create new storage” button.
          </div>
        )}
      </div>
    </>
  )
}

export default KnowledgeBase
