import React from 'react'
import Intro from './components/Intro/Intro'
import { useGetConversationStatsQuery } from '../../store/api/conversation.api'
import { Spin } from 'antd'
import Statistics from './components/Statistics/Statistics'
import { useAppSelector } from '../../hooks/appHook'
import './homepage.less'

const Homepage = () => {
  /** Storage */
  const { user } = useAppSelector((state) => state.profile)

  /** Storage actions */
  const { data, isLoading } = useGetConversationStatsQuery()

  if (isLoading) {
    return <Spin size={'large'} />
  }

  return (
    <>
      <div className="left-header-item">
        <div className="ai-helper-header"><h1>{`Welcome, ${user.name}!`}</h1>
          <p>{`Here's how your AI Agents performed last week.`}</p>
        </div>
      </div>
      {
        data?.count ? <Statistics data={data} /> : <Intro />
      }
    </>
  )
}

export default Homepage
