import React from 'react'
import { Form, Input } from 'antd'
import TestChatButton from '../../TestChatButton/TestChatButton'
import AppPrimaryButton from '../../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useErrorMessage } from '../../../../../hooks/useErrorMessage'
import { useWarningMessage } from '../../../../../hooks/useWarningMessage'
import { requiredValidator } from '../../../../../helpers/validators'
import { useAppSelector } from '../../../../../hooks/appHook'
import {
  useCreateStorageKnowledgeRecordByStorageIdMutation,
  useDeleteStorageKnowledgeRecordByIdMutation,
  useLazyGetStorageKnowledgeRecordsBySearchParamsQuery,
} from '../../../../../store/api/storage.api'
import { StorageKnowledgeRecord } from '../../../../../types/storage.type'
import { RolesEnum } from '../../../../../config/rolesEnum'
import KnowledgeRecordsList from '../../KnowledgeRecordsList/KnowledgeRecordsList'

const SearchAndEditTab = () => {
  const [searchForm] = Form.useForm()

  /** Storage */
  const { currentStorageId } = useAppSelector((state) => state.storage)
  const { user } = useAppSelector((state) => state.profile)

  /** Storage actions */
  const [getStorageKnowledgeRecords, getStorageKnowledgeRecordsQueryResult] =
    useLazyGetStorageKnowledgeRecordsBySearchParamsQuery()

  const [createStorageKnowledgeRecord, createStorageKnowledgeRecordMutationResult] =
    useCreateStorageKnowledgeRecordByStorageIdMutation()

  const [deleteStorageKnowledgeRecord, deleteStorageKnowledgeRecordMutationResult] =
    useDeleteStorageKnowledgeRecordByIdMutation()

  /** Response message handlers */
  useWarningMessage(
    'Sorry, we could not find such information in Knowledge Base. Please, try to use a different search request.',
    getStorageKnowledgeRecordsQueryResult.isSuccess &&
      !getStorageKnowledgeRecordsQueryResult.isLoading &&
      !getStorageKnowledgeRecordsQueryResult.data?.documents.length,
  )
  useErrorMessage("Couldn't load data", getStorageKnowledgeRecordsQueryResult.error)
  useErrorMessage("Couldn't delete item", deleteStorageKnowledgeRecordMutationResult.error)
  useErrorMessage("Couldn't add item", createStorageKnowledgeRecordMutationResult.error)

  const isLoading =
    getStorageKnowledgeRecordsQueryResult.isLoading ||
    createStorageKnowledgeRecordMutationResult.isLoading ||
    deleteStorageKnowledgeRecordMutationResult.isLoading

  const handleOnSearch = (values: { query: string }) => {
    getStorageKnowledgeRecords({ id: currentStorageId, body: { query: values.query } })
  }

  const handleDeleteItem = async (item: StorageKnowledgeRecord) => {
    await deleteStorageKnowledgeRecord({
      id: currentStorageId,
      vectorId: item.id,
    })
    await getStorageKnowledgeRecords({
      id: currentStorageId,
      body: { query: searchForm.getFieldValue('query') },
    })
  }

  const handleEditData = async (value: string, recordId: string) => {
    await createStorageKnowledgeRecord({
      id: currentStorageId,
      body: {
        singleRecord: true,
        metadata: { source: 'edit_data_ui' },
        data: value,
      },
    })
    await deleteStorageKnowledgeRecord({ id: currentStorageId, vectorId: recordId })
    await getStorageKnowledgeRecords({ id: currentStorageId, body: { query: searchForm.getFieldValue('query') } })
  }

  return (
    <div>
      <div className="tab-header">
        <div className="header-description">
          <h2>Search & Edit Data</h2>
          <p>
            Here you may find and edit specific data that has been added to your Knowledge Base. Enter the sentence you
            want to change in the search field below and click “Search Data”.
          </p>
        </div>
        <TestChatButton />
      </div>
      <div className="edit-data-container">
        <Form form={searchForm} onFinish={handleOnSearch}>
          <Form.Item name="query" rules={[{ required: true, validator: requiredValidator('Required!') }]}>
            <Input size="large" />
          </Form.Item>
          <div className="search-buttons-container">
            <Form.Item>
              <AppPrimaryButton type="primary" htmlType="submit" className="app-primary-button-medium" size="middle">
                Search Data
              </AppPrimaryButton>
            </Form.Item>
          </div>
        </Form>
        {user?.role == RolesEnum.ADMIN &&
          getStorageKnowledgeRecordsQueryResult.data?.params &&
          JSON.stringify(getStorageKnowledgeRecordsQueryResult.data?.params)}
        <div className="custom-separator-list-container">
          <KnowledgeRecordsList
            records={getStorageKnowledgeRecordsQueryResult.data?.documents || []}
            loading={isLoading}
            onPressDelete={handleDeleteItem}
            onPressEdit={handleEditData}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchAndEditTab
