import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Input, Popconfirm, Popover, Select, Tooltip } from 'antd'
import Loading from '../../../components/UI/Loading'
import CustomSelect from '../../../components/CustomSelect/CustomSelect'
import TransparentButton from '../../../components/UI/TransparentButton/TransparentButton'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useAppSelector } from '../../../hooks/appHook'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { useSuccessMessage } from '../../../hooks/useSuccessMessage'
import { useDeleteAgentMutation, useEditAgentMutation, useLazyGetAgentByIdQuery } from '../../../store/api/agent.api'
import { requiredValidator } from '../../../helpers/validators'
import { useGetStorageListQuery } from '../../../store/api/storage.api'
import { useAccountUpdateMutation } from '../../../store/api/account.api'
import { RolesEnum } from '../../../config/rolesEnum'

const WarningDeleteAgentPopoverContent = (
  <div>At least one agent is required. Please create different agent before removing this one.</div>
)

const WarningDefaultAgentPopoverContent = <div>This agent is already a default option.</div>

const SettingsTabContent = () => {
  const [form] = Form.useForm()

  /** Storage */
  const { currentAgentId, agent, agents } = useAppSelector((state) => state.agents)
  const { account } = useAppSelector((state) => state.account)
  const { user } = useAppSelector((state) => state.profile)

  /** Storage actions */
  const { data: storages } = useGetStorageListQuery()

  const [getAgent, getAgentQueryResult] = useLazyGetAgentByIdQuery()

  const [updateAgent, updateAgentMutationResult] = useEditAgentMutation()

  const [deleteAgent, deleteAgentMutationResult] = useDeleteAgentMutation()

  const [updateAccount] = useAccountUpdateMutation()

  /** State */
  const [isVisibleDeletePopover, setIsVisibleDeletePopover] = useState<boolean>(false)
  const [isVisibleDefaultAgentPopover, setVisibleDefaultAgentPopover] = useState<boolean>(false)
  const [selectedStorageId, setSelectedStorageId] = useState('')

  /** Response message handlers */
  useErrorMessage('Server error!', updateAgentMutationResult.error || deleteAgentMutationResult.error)
  useSuccessMessage('Agent was successfully updated', updateAgentMutationResult.isSuccess)
  useSuccessMessage('Agent was successfully deleted', deleteAgentMutationResult.isSuccess)

  const selectOptions = useMemo(() => {
    const values: Array<{ label: string; value: string | null }> = [{ label: 'All storages', value: 'all' }]

    storages?.forEach(({ id, name }) => {
      values.push({ value: id, label: name })
    })

    return values
  }, [storages])

  const isCurrentAgentDefault = useMemo(() => {
    return agent?.id === account?.defaultAgentId
  }, [agent, account])

  const onSelectStorage = (value: string) => {
    setSelectedStorageId(value)
  }

  const onSaveConfigs = (values: {
    name: string
    prompt: string
    customFunctionsList: string
    storageId: string
    realtimeEnabled: boolean
  }) => {
    updateAgent({
      id: currentAgentId,
      body: {
        ...values,
        storageId: selectedStorageId === 'all' ? null : selectedStorageId,
        realtimeSettings:
          user?.role == RolesEnum.ADMIN
            ? {
                enabled: values.realtimeEnabled,
              }
            : undefined,
      },
    })
  }

  const onDeleteAgent = useCallback(() => {
    deleteAgent(currentAgentId)
  }, [currentAgentId])

  const onSelectAgentAsDefault = useCallback(() => {
    if (agent?.id) {
      updateAccount({
        defaultAgentId: agent.id,
      })
    }
  }, [agent])

  useEffect(() => {
    if (currentAgentId) {
      getAgent({ id: currentAgentId })
    }
  }, [currentAgentId])

  useEffect(() => {
    const defaultValue = agent?.storageId || 'all'
    setSelectedStorageId(defaultValue)
  }, [agent])

  useEffect(() => {
    if (agent) {
      form.setFieldsValue({
        name: agent.name,
        prompt: agent.prompt,
        customFunctionsList: agent.customFunctionsList,
        realtimeEnabled: agent?.realtimeSettings?.enabled || false,
      })
      if (agent.storageId) {
        setSelectedStorageId(agent.storageId)
      }
    }
  }, [agent])

  if (getAgentQueryResult.isLoading) {
    return (
      <div className="loader-container">
        <Loading />
      </div>
    )
  }

  return (
    <div className="settings-tab">
      <h2>Settings</h2>
      <Form form={form} layout="vertical" onFinish={onSaveConfigs}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, validator: requiredValidator('Name is required!') }]}
          style={{ marginBottom: '12px' }}
        >
          <Input />
        </Form.Item>
        <div className="storage-select-container" key={selectedStorageId}>
          <p className="storage-select-label">
            Default storage{' '}
            <Tooltip title="By default agent will choose storage based on query language.">
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          <CustomSelect options={selectOptions} initialStatus={selectedStorageId} onChange={onSelectStorage} />
        </div>
        {user?.role == RolesEnum.ADMIN && (
          <div>
            <h3>Admin settings</h3>
            <Form.Item
              label="Custom Prompt"
              name="prompt"
              style={{ width: 500 }}
              tooltip={{
                title: 'You can customize your agent prompt to perform any other action that fits system design.',
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input.TextArea autoSize={{ minRows: 5, maxRows: 6 }} />
            </Form.Item>
            <Form.Item
              label="Custom Functions List"
              name="customFunctionsList"
              tooltip={{
                title: 'Add JSON array of custom functions that will be used in this AI Agent',
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input placeholder='["call_google"]' />
            </Form.Item>
            <Form.Item
              label="Is real-time voice mode enabled"
              name="realtimeEnabled"
              tooltip={{
                title: 'You can choose to enable or disable the real-time voice mode.',
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select placeholder="Select status">
                <Select.Option value={true}>Enabled</Select.Option>
                <Select.Option value={false}>Disabled</Select.Option>
              </Select>
            </Form.Item>
          </div>
        )}
        <div className="update-agent-form-buttons" style={{ marginTop: 25 }}>
          <Popover content={WarningDeleteAgentPopoverContent} open={isVisibleDeletePopover}>
            <Popconfirm
              icon={null}
              title="Delete agent"
              description={`Are you sure you want to remove agent
              ${agent?.name}  with all data? This action is not reversible.`}
              onConfirm={onDeleteAgent}
              okText="Delete"
              cancelText="Cancel"
            >
              <div
                onMouseEnter={() => {
                  if (agents.length === 1) {
                    setIsVisibleDeletePopover(true)
                  }
                }}
                onMouseLeave={() => {
                  setIsVisibleDeletePopover(false)
                }}
              >
                <TransparentButton
                  disabled={agents.length === 1 || isCurrentAgentDefault}
                  className="delete-agent-button"
                  title="Delete agent"
                />
              </div>
            </Popconfirm>
          </Popover>
          <TransparentButton className="create-agent-button" title="Save changes" htmlType="submit" />
        </div>
        <Popover content={WarningDefaultAgentPopoverContent} open={isVisibleDefaultAgentPopover}>
          <div
            className="set-default-agent-button-wrapper"
            onMouseEnter={() => {
              if (isCurrentAgentDefault) {
                setVisibleDefaultAgentPopover(true)
              }
            }}
            onMouseLeave={() => {
              setVisibleDefaultAgentPopover(false)
            }}
          >
            <TransparentButton
              disabled={isCurrentAgentDefault}
              className="set-default-agent-button"
              title="Set as default"
              onClick={onSelectAgentAsDefault}
            />
          </div>
        </Popover>
      </Form>
    </div>
  )
}

export default SettingsTabContent
