import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../baseQueryWithReauth'
import { LocalStorage } from 'ts-localstorage'
import { ACCESS_TOKEN } from '../../config/consts'
import {
  EditStorageRequest,
  Storage,
  StorageAddKnowledgeRecordRequest,
  StorageKnowledgeRecord,
  StorageSearchKnowledgeRecordsRequest,
} from '../../types/storage.type'
import { setAddedKnowledgeRecords } from '../slices/storage.slice'

export const storageApi = createApi({
  reducerPath: 'storageApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['GetStorageList', 'Document'],
  endpoints: (builder) => ({
    getStorageList: builder.query<Array<Storage>, void>({
      query: () => ({
        url: `storages`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
      providesTags: [{ type: 'GetStorageList' }],
    }),
    getStorageById: builder.query<Storage, { id: string }>({
      query: ({ id }) => ({
        url: `storages/${id}`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    createStorage: builder.mutation<Storage, { body: Partial<Storage> }>({
      query: ({ body }) => ({
        url: `storages`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(storageApi.util.invalidateTags([{ type: 'GetStorageList' }]))
      },
    }),
    updateStorage: builder.mutation<Storage, EditStorageRequest>({
      query: ({ body, id }) => ({
        url: `storages/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    getStorageKnowledgeRecordsById: builder.query<
      { documents: Array<StorageKnowledgeRecord>; info: string },
      { id: string; documentId?: string }
    >({
      query: ({ id, documentId }) => {
        const searchParams = new URLSearchParams()
        if (documentId) {
          searchParams.append('documentId', documentId)
        }

        return {
          url: `storages/data/${id}?${searchParams.toString()}`,
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
            authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
          },
        }
      },
      providesTags: ['Document']
    }),
    createStorageKnowledgeRecordByStorageId: builder.mutation<
      StorageAddKnowledgeRecordRequest,
      { id: string; body: StorageAddKnowledgeRecordRequest }
    >({
      query: ({ id, body }) => ({
        url: `storages/${id}`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled
        dispatch(setAddedKnowledgeRecords(res?.data?.documents || []))
      },
    }),
    deleteStorageKnowledgeRecordById: builder.mutation<void, { id: string; vectorId: string }>({
      query: ({ id, vectorId }) => ({
        url: `storages/${id}/${vectorId}`,
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    getStorageKnowledgeRecordsBySearchParams: builder.query<
      { documents: Array<StorageKnowledgeRecord>; info: string; answer?: string; params?: any },
      { id: string; body: StorageSearchKnowledgeRecordsRequest }
    >({
      query: ({ id, body }) => ({
        url: `storages/search/${id}`,
        method: 'POST',
        body,
      }),
    }),
    createStorageKnowledgeRecordByUrl: builder.mutation<
      StorageAddKnowledgeRecordRequest,
      { id: string; body: { url: string } }
    >({
      query: ({ id, body }) => ({
        url: `storages/index-url/${id}`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled
        dispatch(setAddedKnowledgeRecords(res?.data?.documents || []))
      },
    }),
    deleteStorage: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `storages/${id}`,
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(storageApi.util.invalidateTags([{ type: 'GetStorageList' }]))
      },
    }),
  }),
})

export const {
  useGetStorageListQuery,
  useLazyGetStorageListQuery,
  useGetStorageByIdQuery,
  useLazyGetStorageByIdQuery,
  useCreateStorageMutation,
  useUpdateStorageMutation,
  useLazyGetStorageKnowledgeRecordsByIdQuery,
  useCreateStorageKnowledgeRecordByStorageIdMutation,
  useLazyGetStorageKnowledgeRecordsBySearchParamsQuery,
  useCreateStorageKnowledgeRecordByUrlMutation,
  useDeleteStorageMutation,
  useDeleteStorageKnowledgeRecordByIdMutation,
} = storageApi
