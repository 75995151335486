import React, { ChangeEvent, useEffect, useReducer, useRef } from 'react'
import { Button, Checkbox, Form, Input, Space, Tooltip } from 'antd'
import TransparentButton from '../../../components/UI/TransparentButton/TransparentButton'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useAppSelector } from '../../../hooks/appHook'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { DATA_HOST, WIDGET_URL } from '../../../config/consts'
import { useSuccessMessage } from '../../../hooks/useSuccessMessage'
import Loading from '../../../components/UI/Loading'
import { whitespaceValidator } from '../../../helpers/validators'
import { useEditAgentMutation, useLazyGetAgentByIdQuery } from '../../../store/api/agent.api'
import { InfoCircleOutlined } from '@ant-design/icons'

export interface IWidgetSettings {
  title: string
  color: string
  position: 'left' | 'right'
  welcomeMessage: string
  defaultOpenType: 'desktopOnly' | 'all' | 'hidden'
  ctaLabel?: string
  ctaUrl?: string
  logoUrl?: string
}

const BrandingTabContent = () => {
  const isMounted = useRef(true)

  const [form] = Form.useForm()

  /** Storage */
  const { user } = useAppSelector((state) => state.profile)
  const { account } = useAppSelector((state) => state.account)
  const { currentAgentId, agent } = useAppSelector((state) => state.agents)
  const { accountId } = user

  /** Storage actions */
  const [getAgent, getAgentQueryResult] = useLazyGetAgentByIdQuery()
  const [updateAgent, updateAgentMutationResult] = useEditAgentMutation()

  /** State */
  const [widgetSettings, setWidgetSettings] = useReducer(
    (current: IWidgetSettings, update: Partial<IWidgetSettings>) => ({
      ...current,
      ...update,
    }),
    {
      color: account.chat?.color || '9966ff',
      position: 'right',
      title: '',
      welcomeMessage: '',
      defaultOpenType: 'hidden',
    },
  )

  /** Response message handlers */
  useErrorMessage('Server error!', updateAgentMutationResult.error)
  useSuccessMessage('Agent was updated successfully!', updateAgentMutationResult.isSuccess)

  const sendIframeMessage = (message: IWidgetSettings) => {
    const iframe = document.getElementById('iframe') as HTMLIFrameElement

    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage(message, '*')
    }
  }
  const onTitleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setWidgetSettings({ title: e.target.value })
  }

  const onColorChange = (color: string) => {
    setWidgetSettings({ color })
  }

  const onWelcomeMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setWidgetSettings({ welcomeMessage: e.target.value })
  }

  const handleCheckboxChange = (checkedValues: CheckboxChangeEvent) => {
    const position = checkedValues.target.value
    setWidgetSettings({ position })
  }

  const handleOpenCloseCheckboxChange = (checkedValues: CheckboxChangeEvent) => {
    setWidgetSettings({ defaultOpenType: checkedValues.target.value })
  }

  const onSaveConfigs = (values: { newTitle: string; welcomeMessage: string }) => {
    const defaultOpenType = widgetSettings.defaultOpenType === 'hidden' ? null : widgetSettings.defaultOpenType

    const request = {
      chatSettings: {
        title: values.newTitle,
        color: widgetSettings.color,
        position: widgetSettings.position,
        welcomeMessage: values.welcomeMessage,
        defaultOpenType,
      },
    }

    updateAgent({ id: currentAgentId, body: request })
  }

  const createWidgetScript = () => {
    const existedScript = document.getElementById('ioni')

    if (existedScript) {
      removeWidgetScript()
    }

    const script = document.createElement('script')

    script.setAttribute('id', `ioni`)
    script.setAttribute('async', 'true')
    script.setAttribute('src', `https://${WIDGET_URL}/widget.js`)
    script.setAttribute('data-open', 'open')
    document.body.appendChild(script)
    return script
  }

  const updateWidgetScript = (accountId: string) => {
    const currentWidget = document.getElementById('ioni')
    let newWidget = null
    if (currentWidget) {
      removeWidgetScript()
      newWidget = createWidgetScript()
    }

    if (newWidget) {
      try {
        if (accountId) {
          newWidget.setAttribute('data-account-id', accountId)
        }
        if (currentAgentId) {
          newWidget.setAttribute('data-agent-id', currentAgentId)
        }

        newWidget.setAttribute('data-host', `${DATA_HOST}`)
      } catch (err) {
        console.log('createWidgetScript ERROR: ', err)
      }
    }
  }

  const removeWidgetScript = () => {
    try {
      const script = document.getElementById('ioni')
      const iframeToRemove = document.getElementById('iframe') as HTMLIFrameElement | null
      const buttonToRemove = document.getElementById('chat-widget-button') as HTMLIFrameElement | null

      if (script) {
        document.body.removeChild(script)
      }
      if (iframeToRemove) {
        document.body.removeChild(iframeToRemove)
      }
      if (buttonToRemove) {
        document.body.removeChild(buttonToRemove)
      }
    } catch (err) {
      console.log('removeWidgetScript ERROR: ', err)
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      createWidgetScript()
    }

    return () => {
      isMounted.current = false
      removeWidgetScript()
    }
  }, [])

  useEffect(() => {
    updateWidgetScript(accountId)
  }, [accountId, currentAgentId])

  useEffect(() => {
    if (currentAgentId) {
      getAgent({ id: currentAgentId })
    }
  }, [currentAgentId])

  useEffect(() => {
    sendIframeMessage({ ...widgetSettings, color: `#${widgetSettings.color}` })
  }, [widgetSettings])

  useEffect(() => {
    if (!agent?.chatSettings) {
      return
    }

    const { color, position, title, welcomeMessage, defaultOpenType } = agent.chatSettings

    setWidgetSettings({
      color,
      position: position ?? 'right',
      title,
      welcomeMessage: welcomeMessage,
      defaultOpenType: defaultOpenType ?? 'hidden',
    })

    form.setFieldsValue({
      newTitle: title,
      welcomeMessage: welcomeMessage,
    })
  }, [agent])

  if (getAgentQueryResult.isLoading) {
    return (
      <div className="loader-container">
        <Loading />
      </div>
    )
  }

  return (
    <Form form={form} onFinish={onSaveConfigs}>
      <h2>Chatbot Widget</h2>
      <p>Adjust the appearance of your chatbot widget according to your company branding.</p>
      <p id="choose-position-text">Position of the chat widget on a screen:</p>
      <Checkbox.Group value={[widgetSettings.position]}>
        <Checkbox value="left" onChange={handleCheckboxChange}>
          Left
        </Checkbox>
        <Checkbox value="right" onChange={handleCheckboxChange}>
          Right
        </Checkbox>
      </Checkbox.Group>
      <p>Chat widget title:</p>
      <Form.Item name="newTitle" rules={[{ validator: whitespaceValidator('Title') }]}>
        <Input.TextArea id="title-input" autoSize={{ minRows: 1 }} onChange={onTitleChange} />
      </Form.Item>
      <p>Chat widget welcome message:</p>
      <Form.Item name="welcomeMessage" rules={[{ validator: whitespaceValidator('Welcome Message') }]}>
        <Input.TextArea id="title-input" autoSize={{ minRows: 1 }} onChange={onWelcomeMessageChange} />
      </Form.Item>
      <p>
        Default open status:{' '}
        <Tooltip
          title="This setting will be applied only to a widget that you add to your website. Demo widget on this page is always
          open."
        >
          <InfoCircleOutlined />
        </Tooltip>
      </p>

      <Checkbox.Group value={[widgetSettings.defaultOpenType]}>
        <Checkbox value="all" onChange={handleOpenCloseCheckboxChange}>
          Open on both mobile and desktop
        </Checkbox>
        <Checkbox value="desktopOnly" onChange={handleOpenCloseCheckboxChange}>
          Open on a desktop version only
        </Checkbox>
        <Checkbox value="hidden" onChange={handleOpenCloseCheckboxChange}>
          Hidden (only icon will be displayed)
        </Checkbox>
      </Checkbox.Group>
      <div>
        <p>Chat widget primary color:</p>
        <div className="colors-container">
          <Button
            onClick={() => onColorChange('1890FF')}
            className="color-button"
            style={{ backgroundColor: '#1890FF' }}
          ></Button>
          <Button
            onClick={() => onColorChange('00B3BE')}
            className="color-button"
            style={{ backgroundColor: '#00B3BE' }}
          ></Button>
          <Button
            onClick={() => onColorChange('099720')}
            className="color-button"
            style={{ backgroundColor: '#099720' }}
          ></Button>
          <Button
            onClick={() => onColorChange('7556EB')}
            className="color-button"
            style={{ backgroundColor: '#7556EB' }}
          ></Button>
          <Button
            onClick={() => onColorChange('DE2828')}
            className="color-button"
            style={{ backgroundColor: '#DE2828' }}
          ></Button>
          <Button
            onClick={() => onColorChange('F223C5')}
            className="color-button"
            style={{ backgroundColor: '#F223C5' }}
          ></Button>
          <Button
            onClick={() => onColorChange('EC7F00')}
            className="color-button"
            style={{ backgroundColor: '#EC7F00' }}
          ></Button>
          <Button
            onClick={() => onColorChange('1E1F21')}
            className="color-button"
            style={{ backgroundColor: '#1E1F21' }}
          ></Button>
          <Button
            onClick={() => onColorChange('89603A')}
            className="color-button"
            style={{ backgroundColor: '#89603A' }}
          ></Button>
          <Button
            onClick={() => onColorChange('878784')}
            className="color-button"
            style={{ backgroundColor: '#878784' }}
          ></Button>
          <Space.Compact className="color-input-container">
            <Input prefix="#" value={widgetSettings.color} onChange={(e) => onColorChange(e.target.value)} />
          </Space.Compact>
        </div>
      </div>
      <TransparentButton className="transparent-button-large" title="Save changes" htmlType="submit" />
    </Form>
  )
}

export default BrandingTabContent
