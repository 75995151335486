import React from 'react';
import TransparentButton from '../../components/UI/TransparentButton/TransparentButton';

type IProps = {
  setKnowledgeBasePage: (key: string) => void
  setAddNewDataActivePage: (key: string) => void
}

const AddDocumentButton = ({setKnowledgeBasePage, setAddNewDataActivePage}:IProps) => {

  const onClick= () => {
    setKnowledgeBasePage('1')
    setAddNewDataActivePage('2')
  }
  return <TransparentButton onClick={onClick} title="Add document" />
}

export default AddDocumentButton;
